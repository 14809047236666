<template>
    <div ref="scrollBarRef" class="o365-datagrid-vertical-scrollbar-container"  @scroll.passive="e => syncScroll(e)">
        <div class="o365-datagrid-vertical-scrollbar"
            :style="{ height: scrollHeight }"></div>
    </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import type DataGridControl from './DataGridControl.ts';

import { InjectionKeys, $t } from 'o365-utils';
import { inject, computed, watch, ref } from 'vue';

const dataGridControl = inject<Ref<DataGridControl>>(InjectionKeys.dataGridControlKey);
if (dataGridControl == null) {
    throw new Error($t('DataGridVerticalScrollBar must be used inside a DataGrid'));
}

const dataGridModels = inject(InjectionKeys.dataGridModelsKey) as any;
const scrollTop = dataGridModels.scrollTop as Ref<number>;
const scrollBarRef = ref<HTMLElement>();

const dataHeight = computed(() => {
    return dataGridControl.value.props.getRowHeight ? dataGridControl.value.virtualScrollApi.vs_totalHeight : null; 
})
const dataLength = computed(() => {
    const dataGridProps = dataGridControl.value.props;
    if (dataGridProps.dataObject && dataGridProps.dataObject.hasNodeData && dataGridProps.dataObject.nodeData.enabled) {
        return dataGridProps.dataObject.data.length;
    } else if (dataGridProps.dynamicLoading && dataGridProps.dataObject) {
        //return props.dataObject.data.length;
        // const rowCount = props.dataObject.filterObject.appliedFilterString ? props.dataObject.filteredRowCount : props.dataObject.rowCount;
        const rowCount = dataGridProps.dataObject.rowCount;
        return rowCount === -1 || rowCount == null ? dataGridProps.dataObject.data.length : rowCount;
    } else if (dataGridProps.dataObject) {
        return dataGridProps.dataObject.data.length;
    } else if (dataGridControl.value.utils?.filteredRowCount != null) {
        return dataGridControl.value.utils.filteredRowCount;
    } else {
        return dataGridProps.data?.length;
    }
});

const scrollHeight = computed(() => {
    return (dataHeight.value ?? (dataLength.value * dataGridControl.value.props.rowHeight)) + 'px'
});

let scrollUpdateIsInteranl = true;
watch(() => scrollTop.value, (newScroll) => {
    if (!scrollUpdateIsInteranl && scrollBarRef.value) {
        if (scrollBarRef.value.scrollHeight == 0) { return; }
        scrollBarRef.value.scrollTop = newScroll ?? 0;
        scrollTop.value = scrollBarRef.value.scrollTop;
    }
    scrollUpdateIsInteranl = false;
});

function syncScroll(pEvent: Event) {
    const target = pEvent.target as HTMLElement;
    if (scrollTop.value != target.scrollTop) {
        scrollTop.value = target.scrollTop;
        scrollUpdateIsInteranl = true;
    }
}
</script>